<template>
  <div v-if="isWindowOpened == false" class="chat" id="azd-chat" v-bind:style="{bottom: '5%', top: '90%'}">
    <ChatBubble
      :unread="unreadMessages"
      :showWelcome="!isWindowOpened"
      :welcome="'Kryštof'"
      @click="handleBubblePress"
    />
  </div>
  <div v-else class="chat" id="azd-chat">
    <ChatWindow :isOpened="isWindowOpened">
      <template v-slot:header>
        <div class="header">
          
          <div class="mira">
            <img src="@/assets/images/logo.png" alt="" />
          </div>
          <div class="title">
            <div class="title-main">Kryštof</div>
            <div class="title-subtitle"></div>
          </div>
          <div class="actions">
            <a
              href="#"
              @click.prevent="this.$store.dispatch('Global/toggleFullscreen')"
              class="action"
            >
              <ArrowsExpandIcon />
            </a>
            <a href="#" class="action" @click.prevent="handleBubblePress">
              <MinusSmIcon />
            </a>
          </div>
        </div>
      </template>

      <template v-slot:content>
        <message-window />
      </template>

      <template v-slot:input>
        <chat-input @onMessageSent="handleMessageSent" :enabled="isConnected" />
      </template>
    </ChatWindow>
    <ChatBubble
      :unread="unreadMessages"
      :showWelcome="!isWindowOpened"
      :welcome="'Chatbot Test'"
      @click="handleBubblePress"
    />
  </div>
</template>

<script>
import ChatWindow from "./components/ChatWindow.vue";
import ChatBubble from "./components/ChatBubble.vue";
import ChatInput from "./components/ChatInput.vue";
import MessageWindow from "./components/MessageWindow.vue";

import NotificationGroup from "./components/NotificationGroup.vue";
import eventBus from "@/model/eventBus";
import { mapGetters } from "vuex";

import { ArrowsExpandIcon, MinusSmIcon } from "@heroicons/vue/outline";

export default {
  name: "App",
  components: {
    ChatWindow,
    ChatBubble,
    ChatInput,
    MessageWindow,
    NotificationGroup,
    ArrowsExpandIcon,
    MinusSmIcon,
  },
  data() {
    return {
      currentLocation: window.location.href,
      locationChanged: false,
    };
  },
  watch: {
    currentLocation: {
      handler() {
        this.locationChanged = true;
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    this.$store.dispatch("Messages/loadStorageData");
  },

  computed: {
    ...mapGetters({
      isWindowOpened: "Global/isOpened",
      isConnected: "Global/isConnected",
      unreadMessages: "Messages/getUnreadMessagesCount",
    }),
  },

  methods: {
    _sendMessage({ message }) {
      this.$store.dispatch("Messages/sendMessage", {
        message,
      });
    },

    handleBubblePress() {
      //this.isWindowOpened = !this.isWindowOpened;
      this.$store.dispatch("Global/toggleIsOpened");
      eventBus.$emit("windowOpened");
      if (this.locationChanged == true) {
        this.locationChanged = false;
        this.$store.dispatch("Messages/sendLocation", { location: (this.currentLocation).toString() });
      }
    },
    handleMessageSent(mMessage) {
      this._sendMessage(mMessage);
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #004872;
  margin-top: 60px;
}
</style>
