<template>
  <div class="message-window">
    <message-group
      v-for="group in groupedMessages"
      :group="group"
      :key="group.uuid"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventBus from "@/model/eventBus";

import MessageGroup from "./messages/MessageGroup.vue";

export default {
  name: "MessageWindow",
  components: { MessageGroup },

  updated() {
    this.scrollDown();
  },

  mounted() {
    eventBus.$on("scrollDownPlease", () => {
      this.scrollDown();
    });
  },

  methods: {
    scrollDown() {
      this.$nextTick(() => {
        var elem = this.$el;
        elem.scrollTop = elem.scrollHeight;
      });
    },
  },

  computed: {
    ...mapGetters({
      groupedMessages: "Messages/getMessages",
    }),
  },
};
</script>

<style scoped lang="scss">
</style>
