<template>
  <div class="message" v-html="message.text"></div>
</template>

<script>
import { ChatTextMessage } from '@/model/message-types';
export default {
  name: "MessageItem",
  props: {
    message: ChatTextMessage,
  },
};
</script>

<style scoped lang="scss">
</style>
