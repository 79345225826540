import { createStore } from 'vuex'

import Messages from './modules/messages';
import Global from './modules/global';
import files from './modules/files';

export default createStore({
  modules: {
    Messages, Global, files
  }
})