<template>
  <div class="message-option">
    <div class="message">{{ message.text }}</div>
    <div v-if="showOptions" class="chips-group">
      <div class="chips-description">{{ message.description }}</div>
      <div class="chip-items">
        <template v-for="option in message.options" :key="option.value">
          <option-message-item
            :text="option.text"
            :val="option.value"
            @click="handleChipPress(option)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ChatOptionMessage } from "@/model/message-types";
import OptionMessageItem from "./OptionMessageItem.vue";

export default {
  components: { OptionMessageItem },
  name: "OptionMessage",
  props: {
    message: ChatOptionMessage,
    showOptions: Boolean,
  },

  methods: {
    handleChipPress(option) {
      this.$store.dispatch("Messages/sendMessage", {
        message: option.text,
        option: option.value,
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
