<template>
  <div class="busy-indicator" :class="{ 'busy-indicator--hidden': !isBusy }">
    <div class="busy-spinner"></div>
    <div class="busy-message">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "BusyIndicator",
  props: {
    isBusy: Boolean,
    text: String,
  },
};
</script>

<style scoped lang="scss">
</style>
