<template>
  <div
    class="okynko window"
    v-bind:class="{
      'window--opened': isOpened,
      'window--fullscreen': isFullscreen,
    }"
  >
    <div class="window--header">
      <slot name="header"></slot>
    </div>

    <div class="window--content">
      <busy-indicator
        :isBusy="connectionState === 99 ? false : true"
        :text="connectionStateMessage"
      />
      <slot name="content"></slot>
    </div>

    <div class="window--notifications">
      <slot name="notifications"></slot>
    </div>

    <div class="window--footer">
      <slot name="input" v-if="inputVisibility"> </slot>
    </div>

    <!-- Conditionally render file upload input -->
    <div class="file-upload-container" v-if="shouldShowFileUpload">
      <input type="file" @change="handleFileChange" ref="fileInput" class="file-input">
      <button @click="triggerFileInput" class="custom-upload-button">Nahrát životopis</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from 'vuex';
import BusyIndicator from "./BusyIndicator.vue";

export default {
  name: "ChatWindow",
  components: { BusyIndicator },
  props: {
    isOpened: Boolean,
  },
  data() {
    return {
      file: null, // To store the selected file
    };
  },

  computed: {
    ...mapGetters({
      isFullscreen: "Global/isFullscreen",
      connectionState: "Global/getConnectionState",
      connectionStateMessage: "Global/getConnectionStateMessage",
      isConnected: "Global/isConnected",
      inputVisibility: "Messages/getInputVisibility",
      fileVisibility: "Messages/getFileVisibility"
    }),
    shouldShowFileUpload() {
      return process.env.VUE_APP_FILE_TRANSFER === 'true' && this.fileVisibility;
    },
  },

  methods: {
    ...mapActions('files', ['uploadFile']),
    ...mapActions('messages', ['sendMessage']),
    _sendMessage({ message }) {
      this.$store.dispatch("Messages/sendMessage", {
        message,
      });
    },
    async handleFileChange(event) {
      this.file = event.target.files[0];
      const myMessage = {
        message: ' '
      };
      if (this.file) {
        try {
          await this.uploadFile({ file: this.file });
          this.$refs.fileInput.value = '';
          this.file = null;
          myMessage.message = String(process.env.VUE_APP_FILE_SENT_MESSAGE);
        } catch (error) {
          myMessage.message = `${(String(process.env.VUE_APP_FILE_ERROR_MESSAGE))}: ${error}`;
        }
        this._sendMessage(myMessage);
      }
    },
    handleUploadDrop(event) {
      event.preventDefault();
      if (!this.isConnected) return;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/css/components/_files';
</style>
