export const MessageType = { Text: "text", Image: "image", Video: "video", Option: "option", Suggestion: "suggestion" };
export class ChatMessageGroup {
    constructor(system) {
        this._system = system;
        this._messages = [];
        this._uuid = uuidv4();
    }

    get system() { return this._system; }
    get messages() { return this._messages; }
    get uuid() { return this._uuid; }
    addMessage(message) {
        if (message instanceof ChatMessage)
            this.messages.push(message);
    }


    assignData(data) {
        Object.assign(this, data);
        this._messages = [];
        data._messages.forEach(message => {
            const messageInstance = getMessageInstance(message);
            this.addMessage(messageInstance);
        });

        function getMessageInstance(message) {
            switch (message._messageType) {
                case MessageType.Text: return Object.assign(new ChatTextMessage(), message);
                case MessageType.Video: return Object.assign(new ChatVideoMessage(), message);
                case MessageType.Image: return Object.assign(new ChatImageMessage(), message);
                case MessageType.Option: return getOptionMessageInstance(message);
                case MessageType.Suggestion: return getOptionMessageInstance(message);
            }

            function getOptionMessageInstance(message) {
                const optionMessage = new ChatOptionMessage();
                optionMessage.assignData(message);
                return optionMessage;
            }
        }
    }

}

export class ChatMessage {
    constructor(text) { this._text = text; this._uuid = uuidv4(); }
    get text() { return this._text; }
    get uuid() { return this._uuid; }
    get messageType() { return this._messageType; }
}

export class ChatTextMessage extends ChatMessage {
    constructor(text) {
        super(text);
        this._messageType = MessageType.Text;
    }
}

export class ChatImageMessage extends ChatMessage {
    constructor(title, source, description, alt) {
        super(title);
        this._source = source;
        this._description = description;
        this._alt = alt;
        this._messageType = MessageType.Image;
    }

    get alt() { return this._alt; }
    get title() { return this._text; }
    get source() { return this._source; }
    get description() { return this._description; }
}

export class ChatVideoMessage extends ChatMessage {
    constructor(title, source, description, alt) {
        super(source);
        this._title = title;
        this._source = source;
        this._description = description;
        this._alt = alt;
        this._messageType = MessageType.Video;
    }
    get alt() { return this._alt; }
    get title() { return this._title; }
    get source() { return this._source; }
    get description() { return this._description; }
}

export class ChatOptionMessage extends ChatMessage {
    constructor(text, description) {
        super(text);
        this._messageType = MessageType.Option;
        this._description = description;
        this._options = [];
    }
    get description() { return this._description; }
    get options() { return this._options; }
    addOption(option) {
        if (option instanceof ChatOptionMessageItem)
            this.options.push(option);
    }

    assignData(data) {
        Object.assign(this, data);
        this._options = [];
        data._options.forEach(opt => {
            this.addOption(new ChatOptionMessageItem(opt._text, opt._value));
        });
    }
}

export class ChatOptionMessageItem {
    constructor(text, value) {
        this._text = text;
        this._value = value;
    }
    get value() { return this._value; }
    get text() { return this._text; }
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}